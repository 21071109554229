import {
  MaterialList, savePermission, editRegionBinding, getNoBound } from "./api";
import { personnelRecordsManagement } from "../businessPersonnelManagement/api"
import { findShopList } from "@/views/basicInformation/components/addPerson/api";
import mangeStore from "@/views/basicInformation/components/mangeStore";
import { flushAccountRegionShop } from "@/views/basicInformation/api";
import officePop from "@/views/basicInformation/components/officePop";
export default {
  name: "index",
  components:{
    officePop,
    mangeStore
  },
  data() {
    return {
      // 面包屑数据
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/basicInfo?type=1",
          name: "basicInfo?type=1",
          title: "人员账号管理"
        },
        {
          path: "/editPerson",
          name: "editPerson",
          title: this.$route.query.type == '管理账号'?'编辑管理账号':'编辑用户账号'
        }
      ],
      //===========================业务人员相关===============================//
      //业务人员表头列
      columns: [
        {
          label: '档案编号',
          prop: 'businessFileCode',
          width: 181,
          scopedSlots: { customRender: 'businessFileCode' },
          ellipsis: true,
          slot:'businessFileCode'
        },
        {
          label: '姓名',
          prop: 'name',
          width: 140,
          ellipsis: true,
          scopedSlots: { customRender: 'name' },
        },
        {
          label: '联系电话',
          prop: 'mobile',
          width: 200,
          ellipsis: true,
          scopedSlots: { customRender: 'mobile' },
        },
      ],
      //表格数据
      tableData:[],
      //分页 -> 数量很少 暂时不需要
      pager: {
        pageNo: 1,
        pageSize: 999,
        count: 0
      },
      personId:'',//选中的人员档案id
      businessFlag:false,//是否是业务人员
      //===========================业务人员相关End===============================//
      // 销售组织数据
      baseMatklList: [
        {
          id: 0,
          matklName: '全部',
          isCheck: false
        }
      ], // 物料组
      loginSystemList: [],// 登陆系统
      shopList: [
        // {
        //   code: 0,
        //   name: '全部',
        //   isCheck: false
        // }
      ], // 门店列表
      isSaleMan: 1,
      accountType: '',
      accountName: '',
      name: '',
      phone: '',
      eamil: '',
      shopIds: [],
      saleName: undefined,
      shopName: [],
      SystemName: undefined,
      baseMatkName: undefined,
      materialGroupGroup: [],
      isActiveLevel: 0,
      problemLevel: 0,
      accountid: '',
      spinning: false,
      showText: false,
      showTextE: false,
      ckName: [],
      ckList: [
        {
          id: 0,
          name: '全部',
          isCheck: false
        }
      ],
      positionList: [
        {
          code: 0,
          name: '全部',
          isCheck: false
        }
      ], // 岗位数据
      isActivePLevel: [],
      positinLevel: [],
      firstPostionArr:[],
      isActiveRLevel: 0,
      roleLevel: 0,
      selectShop: [],
      selectShopName: [],
      StoreItem: [],
      StoreName: [],
      shopCkList: [],
      roleList: [],// 角色
      isActive: 0,
      ck: undefined,
      shop: undefined,
      contacts: '', //联系人
      contactsPhone: '', // 联系电话
      email: '', // 邮箱
      content: '',
      title: '',
      problemType: '', // 问题类型
      fileList: [],
      message: '',
      onluyOneMessage: false,
      previewVisible: false,
      previewImage: '',
      radio: 'radio',
      // pager: {
      //   pageNo: 1,
      //   pageSize: 10,
      //   count: 10
      // },
      showBottom: false,
      tableLoading: false,
      partnerName: '',
      // personId: '',
      selRows: [],
      pageLoadFlag: false,
      visible: false,
      edit: true,
      canChange: true,
      canselect: true,
      materialGroup: [],// 物料组选中数据
      roleGroup: [],// 角色选中数据
      shopSelect: [],
      storeSelect: [],
      MaterLength: '',
      contactvalue: '',
      Personvalue: '',
      emailValue: '',
      showbutton: true,
      buttonDisAbled: true,
      showAllBUtton: true,
      loseButton: true,
      nocticeList: [],// 通知列表
      isActiveNoticeLevel: 0,
      NoticeLevel: 0,
      todoList: [],// 通知列表
      todoCherkList:[],//选中待办
      nocticeCherkList:[],//选中通知
      isActivetodoLevel: 0,
      todoLevel: 0,
      disabled: false,
      firstPostionList:[],
      firstPostionListShow:[],
      positionAAA:[],
      addressHx:[],
      addressArrGroup:[],
      addressNameArr:[],
      isShowShop:false,
      visibleshop:false,
      mesageInfoTips:'',
      fetching:false,
      AssignOrViewVisible:false,
      markrtIds:[],
      accountHisenseType: false, // 是否是海信系
      changeNum:0,
      bindCachePosts: [],
      filterPosts: [14181287851, 14181287853],
      // 运营经理岗位列表
      operateMangePosts: [14181287852, 14181287855],
      // 操盘手
      traderPosts: [14181287847],
      // 下沉
      sinkPosts: [17594638392],
      // 自有渠道
      ownChannelGridManager: [20231110],
      areaInfo:[],
      accountTypeUserShow:'',//是否是用户账号登录用户账号
      personIdShow:false,
      JurisdictionalAreaList:[],
      officePopups:false,
      ldapUserId:'',
      marketCenterIds:'',
      checkRowId:'',
      noBoundGrid: true, // 没绑定网格区域
    };
  },
  mounted() {
    this.eamil = this.$route.query.email
    this.phone = this.$route.query.phone
    this.accountName = this.$route.query.loginAccount
    // this.clear()
    this.name = this.$route.query.name
    this.accountid = this.$route.query.id
    this.accountType = this.$route.query.type
    this.accountTypeUserShow = (this.$route.query.accountType == 'user'&&this.$store.state.user.userInfo.account.type !== 'main')?true:false
    this.getInfo()
    if(this.$store.state.user.userInfo.isAgentOperator == 0) {
      this.isShowShop = false
    } else {
      this.isShowShop = true
    }

  },
  methods: {
    // 清缓存
    async clear() {
      flushAccountRegionShop({account:this.accountName }).then(res => {
      })

    },

    openAssignOrViewVisible(){
      // 如果之前是网格经理 现在更改了  需要弹出提示
      if(this.verificationPosition()&&(!this.ldapUserId||this.markrtIds)){
        this.onluyOneMessage = true
        return
      }
      if (this.firstPostionArr.length <= 0 ) {
        this.$message.warning('请选择岗位!')
        return
      }
      if(!this.personId ){
        this.$message.warning('请选择人员档案')
        return false
      }
      if (this.markrtIds.length <= 0 ) {
        this.$message.warning('请选择物料组!')
        return
      }
      this.AssignOrViewVisible = true
      this.changeNum++
    },
    onAddressChange(addressValArr,addressName) {
      if(addressValArr.length > 0 ){
        this.addressArrGroup.push(addressValArr[2])
        this.addressNameArr.push(addressName)
        this.addressHx = []
        let data = {
          accountId:'',
          countyCodeList:[addressValArr[2]],
          positionIdList:this.firstPostionArr
        }
        this.fetching = true
        findShopList(data).then(res=>{
          this.fetching = false
          this.shopList.push(...res.data.list)
        })
      }
    },
    clearAddress(){
      this.addressArrGroup = []
      this.addressNameArr = []
      this.shopList = []
      this.shopIds = []
      this.shopName = []
    },

    async clearAddressItem(item,index){
      this.shopList =  this.shopList.filter(it=>it.countyCode !==this.addressArrGroup[index]);
       let nameList = []
       let idList = []
       if( this.shopList.length>0){
         this.shopList.forEach(item=>{
           if(this.shopName.includes(item.name)){
             nameList.push(item.name)
             idList.push(item.id)
           }
         })
      }
      this.shopIds = idList
      this.shopName = nameList
      this.addressArrGroup.splice(index, 1)
      this.addressNameArr.splice(index, 1)

    },
    getStoreList(data){
      return findShopList(data)
    },
// 判断档案和岗位是否都是网格经理  如果当前页面的岗位选择了自有渠道网格经理但是选择的档案里的岗位不是网格经理的话 就弹出提示
    isgridManager(){
      if (this.firstPostionArr.indexOf(20231110) !== -1 && this.checkRowId !== 20231110){
        return true
      }else{
        return false
      }
    },

    //===================业getOffice务人员相关===================//
    // 选择项更改
    onChange(selRows) {
      this.checkRowId = selRows?selRows[0].jobsId:''
      // 返回的如果是自有渠道网格经理  赋值办事处和markrtIds
      // if ( this.checkRowId == 20231110){
      //   this.ldapUserId=selRows?selRows[0].ldapUserId:''
      //   this.markrtIds=selRows?selRows[0].markrtIds:''
      // }
      if(this.isShowShop) {
        if (this.personId && selRows.length>0 && selRows[0].id != this.personId ) {
          this.$message.warning('档案已变更，请重新选择管辖区域和管辖门店。')
          this.clear()
        }
      }

      this.materialGroupGroup.forEach(item => {
        item.isCheck = false
      })

      const matklIdsArr = selRows&&selRows[0]?selRows[0].matklIds.split(','):'';
      const CheckArr = []
      for (let item of this.materialGroupGroup) {
        for (const maItem of matklIdsArr) {
          if(item.id == maItem){
            item.isCheck = true;
            CheckArr.push(item)
          }
        }
      }
      //人员档案id -> 提交传参用
      this.personId = selRows[0]?selRows[0].id:'';
      this.markrtIds = []
      this.materialGroupGroup.forEach(item => {
        if (item.isCheck == true && item.id !== 0) {
          this.markrtIds.push(item.id)
        }
      })

      // 判断是否是海信系 物料组包含code就是海信系
      if(
        this.markrtIds.includes(20160119008)  || this.markrtIds.includes(20160119013) ||
        this.markrtIds.includes(20160119014) ||this.markrtIds.includes(20160119018)  ||
        this.markrtIds.includes(20160119020) ||this.markrtIds.includes(20160119022)
      ) {
        this.accountHisenseType = true
      } else {
        this.accountHisenseType = false
      }
      // 全部按钮赋值
      if((this.materialGroupGroup.length-1) === CheckArr.length){
        this.materialGroupGroup[0].isCheck = true;
      }else{
        this.materialGroupGroup[0].isCheck = false;
      }
      if(this.isgridManager()){
        this.officePopups = true
        return
      }
    },
    async getPersonList() {
        const data = {
            accountId:this.$route.query.id,
            pageNo:this.pager.pageNo,
            pageSize: this.pager.pageSize
        }
        const res = await personnelRecordsManagement(data)
        this.tableData = res.data.list
        this.pager.pageNo = res.data.currentPage
        this.pager.pageSize = res.data.pageSize
        this.pager.count = res.data.totalRows
        if(this.id){
           //默认选中
          this.tableData.forEach((item,idx) => {
            if(item.id == this.id){
               //如果有id -》业务人员 -》 默认选中
               this.$nextTick(()=>{
                $('.busPerListBox .el-table .el-table__fixed tbody tr').eq(idx).find('input').trigger('click');
               })
            }
          })
        }
    },
    // 表格方法 如果为true 就可以勾选 为false就不能
    // checkSelectable() {
    //   return !this.accountTypeUserShow
    // },
    // 编辑表格
    editTble(selRows) {
      this.$router.push({
        path: "/businessAddEdit",
        query: {
          id: selRows.id,
          isNoShenPi:true,
          jobsName: selRows.jobsName,//岗位
          custFullName: selRows.custFullName,//商家名称|代理商全称
          cisCode: selRows.cisCode,//CIS编码
          custAccountName: selRows.custAccountName,//账号名称|用户账号
          source:'person'
        },
      });
    },
    //===================业务人员相关End===================//
    // 验证手机号是否正确
    changePhone(e) {
      var reg_tel = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;    //11位手机号码正则
      if (!reg_tel.test(e.target.value)) {
        this.showText = true
        return false
      } else {
        this.showText = false
      }
    },
    changeEmail(e) {
      var re = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
      if (!re.test(e.target.value)) {
        this.showTextE = true
        return false
      } else {
        this.showTextE = false
      }
    },
    // 点击全选触发
    checkAll(arr, modelList) {
      // arr:change中的数组 ，  modelList:下拉框List
      let length = arr.length
      let list = arr
      arr.forEach(element => {
        // 当数组中存在0，说明此时进行全选/取消全选
        if (element == '0') {
          // 当数组长度为最大长度且最后一个元素为0时，说明此时在全选的基础上又点击全选，则取消全选
          if (length - 1 == modelList.length && arr[0] == '0') {
            list = []
          } else {
            // 当不是取消全选操作，只要数组中出现了0则说明进行了全选操作
            list = []
            for (let i in modelList) {
              if (modelList[i].id !== 0) {
                list.push(modelList[i].id)
              }

            }
          }
        }
      })
      return list
    },
    // 仓库全选
    handCk(data) {
      this.ckName = this.checkAll(data, this.shopCkList)
    },
    // 清空仓库
    clearStoreItem() {
      this.ckName = []
    },
    goback() {
      // 返回人员管理页面
      this.$router.push({
        path: '/basicInfo',
        query: {
          type: 1
        }
      })
    },
    // 获取物料组 管辖门店
    async getInfo() {
      this.clear()
      this.spinning = true
      this.shopCkList = [
        {
          id: 0,
          name: '全部',
          isCheck: false
        }
      ]
      const data = {
        custId: this.$route.query.shopId,
        custAccountId: this.accountid,
      }
      const res = await MaterialList(data)
      const result1 = await getNoBound(this.$route.query.loginAccount)
      if(result1 && result1.data){
        this.noBoundGrid = result1.data.data == false ? true : false
      }
      if (this.accountType == '用户账号') {
        if (res.data.data.baseMatklList.length > 0) {
          let wuliaozuNum = []
          res.data.data.baseMatklList.forEach(item => {
            item.isCheck = item.isCheck === 'true'
            if( item.isCheck == true) {
              wuliaozuNum.push(item)
            }
          })
          if(wuliaozuNum.length == res.data.data.baseMatklList.length) {
            this.materialGroupGroup = [{
              id: 0,
              name: '全部',
              isCheck: true
            }]
          } else {
            this.materialGroupGroup = [{
              id: 0,
              name: '全部',
              isCheck: false
            }]
          }
          this.materialGroupGroup.push(...res.data.data.baseMatklList)
        }
      } else {
        if (res.data.data.baseMatklList.length > 0) {
          this.materialGroupGroup = []
          res.data.data.baseMatklList.forEach(item => {
            item.isCheck = true
          })
          this.materialGroupGroup.push(...res.data.data.baseMatklList)
        }
      }
      this.markrtIds = []
      this.materialGroupGroup.forEach(item => {
        if (item.isCheck == true && item.id !== 0) {
          this.markrtIds.push(item.id)
        }
      })
      if(
        this.markrtIds.includes(20160119008)  || this.markrtIds.includes(20160119013) ||
        this.markrtIds.includes(20160119014) ||this.markrtIds.includes(20160119018)  ||
        this.markrtIds.includes(20160119020) ||this.markrtIds.includes(20160119022)
      ) {
        this.accountHisenseType = true
      }
      //模拟id
      // this.id = '22565079';
      this.id = res.data.data.personId ? res.data.data.personId:'';
      this.personIdShow = res.data.data.personId ? true : false;
      //获得信息后 执行 确保异步先后顺序
      if(res){
        this.getPersonList()
      }
      // 待办
      let daibanNum = []
      if (res.data.data.dealList.length > 0) {
        
        res.data.data.dealList.forEach(item => {
          item.isCheck = item.isCheck === 'true'
          if( item.isCheck == true) {
            daibanNum.push(item)
          }
        })
        if(daibanNum.length == res.data.data.dealList.length) {
          this.todoList = [{
            id: 0,
            name: '全部',
            isCheck: true
          }]
        } else {
          this.todoList = [{
            id: 0,
            name: '全部',
            isCheck: false
          }]
        }
        this.todoList.push(...res.data.data.dealList)
        this.todoCherkList = res.data.data.dealList.filter(it=>it.isCheck)
      }
      // 公告
      let noticeNum = []
      if (res.data.data.noticeList.length > 0) {
        res.data.data.noticeList.forEach(item => {
          item.isCheck = item.isCheck === 'true'
          if( item.isCheck == true) {
            noticeNum.push(item)
          }
        })
        if(noticeNum.length == res.data.data.noticeList.length) {
          this.nocticeList = [{
            id: 0,
            name: '全部',
            isCheck: true
          }]
        } else {
          this.nocticeList = [{
            id: 0,
            name: '全部',
            isCheck: false
          }]
        }
        this.nocticeList.push(...res.data.data.noticeList)
        this.nocticeCherkList = res.data.data.noticeList.filter(it=>it.isCheck)
      }
      // 职务
      if (res.data.data.workList.length > 0) {
        this.positionList = []
        res.data.data.workList.forEach(item => {
          if (item.isCheck == 'true') {
            this.isActivePLevel .push(item.id)
            this.bindCachePosts = JSON.parse(JSON.stringify( this.firstPostionArr))
            //限制更改物料组
            if (item.id == '14181287855' ||  item.id == "14181287847" || item.id == "14181287852" || item.id == "17594638392"||item.id == "20231110") {
              this.businessFlag = true
            }
            this.positinLevel.push(item.id)
            this.positionAAA.push(item.id)
          }
          item.isCheck = item.isCheck === 'true'
        })
        this.positionList.push(...res.data.data.workList)
        this.firstPostionList.push(...res.data.data.workList)
        // 如果是用户账号 前端筛去老板岗位不展示
        if (this.$route.query.type=='用户账号'){
          this.firstPostionList = this.firstPostionList.filter((val) => val.name !== '老板')
        }
        res.data.data.workList.forEach(item=>{
          if(item.isCheck){
            this.firstPostionArr.push(item.id)
            this.firstPostionListShow.push(item)
          }
        })
        // 如果是运营经理业务员 运营经理市场推广 下沉渠道专职经理 并且是代理运营商  展示管辖区域&管辖门店
        if( (
            this.firstPostionArr.indexOf(14181287852) !== -1
            || this.firstPostionArr.indexOf(14181287855) !== -1
            || (this.firstPostionArr.indexOf(17594638392) !== -1 && this.noBoundGrid == true)
          )
          && this.$store.state.user.userInfo.isAgentOperator == 1 ){
          this.isShowShop = true
        } else {
          this.isShowShop = false
        }
        // 如果非代理运营商  岗位移除自有渠道网格经理 不能选择
        if(this.$store.state.user.userInfo.isAgentOperator == 0){
          this.firstPostionList = this.firstPostionList.filter(function(obj) {
            return obj.id !== 20231110;
          });
        }
      }
      let roleNum = []
      if (res.data.data.roleList.length > 0) {
        res.data.data.roleList.forEach(item => {
          item.isCheck = item.isCheck === 'true'
          if(item.isCheck == true) {
            roleNum.push (item)
          }
        })
        this.roleList = res.data.data.roleList
        if(roleNum.length == res.data.data.roleList.length) {
          this.roleList = [{
            id: 0,
            name: '全部',
            isCheck: true
          }]
        } else {
          this.roleList = [{
            id: 0,
            name: '全部',
            isCheck: false
          }]
        }
        this.roleList.push(...res.data.data.roleList)
      }
      this.shopCkList.push(...res.data.data.shopCkList)
      res.data.data.shopCkList.forEach(shopItem => {
        if (shopItem.isCheck == 'true') {
          this.ckName.push(shopItem.id)
          this.selectShopName.push(shopItem)
        }
      })

      // 查询数据库的管辖区域
      this.getNewInfo()

      this.spinning = false
    },
    //是否根据输入项进行筛选
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    btnroleClickLevel(index, id, item) {
      if(this.mainEditMsg()){
        return
      }
      this.isActiveRLevel = index
      this.roleLevel = id
      item.isCheck = !item.isCheck

      if (index == 0 && item.isCheck == true) {
        this.roleList.forEach(item => {
          item.isCheck = true
        })
      }
      if (index == 0 && item.isCheck == false) {
        this.roleList.forEach(item => {
          item.isCheck = false
        })
      }
      let materialNum = []
      this.roleList.forEach(item=>{
        if(item.isCheck == true && item.name!=='全部') {
          materialNum.push(item)
        }
      })
      if(materialNum.length == this.roleList.length-1 ){
        this.roleList[0].isCheck = true
      } else {
        this.roleList[0].isCheck = false
      }
    },
    // 按钮点击样式切换
    btnClickLevel(index, id, item) {
      if(this.mainEditMsg()){
        return
      }
      if(this.businessFlag){
        this.$message.warning('业务人员物料组根据档案信息获取，点击档案编号可修改',3);
        return
      }
      this.isActiveLevel = index
      this.problemLevel = id
      item.isCheck = !item.isCheck
      if (index == 0 && item.isCheck == true) {
        this.materialGroupGroup.forEach(itemMaterial => {
          itemMaterial.isCheck = true
        })
      }
      if (index == 0 && !item.isCheck) {
        this.materialGroupGroup.forEach(item => {
          item.isCheck = false
        })
      }
      let materialNum = []
      this.materialGroupGroup.forEach(item=>{
        if(item.isCheck == true && item.name!=='全部') {
          materialNum.push(item)
        }
      })
      if(materialNum.length == this.materialGroupGroup.length-1 ){
        this.materialGroupGroup[0].isCheck = true
      } else {
        this.materialGroupGroup[0].isCheck = false
      }

      if (index == 0 && item.isCheck == false) {
        this.materialGroupGroup.forEach(item => {
          item.isCheck = false
        })
      }
      this.markrtIds = []
      this.materialGroupGroup.forEach(item => {
        if (item.isCheck == true && item.id !== 0) {
          this.markrtIds.push(item.id)
        }
      })
      if(
        this.markrtIds.includes(20160119008)  || this.markrtIds.includes(20160119013) ||
        this.markrtIds.includes(20160119014) ||this.markrtIds.includes(20160119018)  ||
        this.markrtIds.includes(20160119020) ||this.markrtIds.includes(20160119022)
      ) {
        this.accountHisenseType = true
      } else {
        this.accountHisenseType = false
      }


    },
    // 判断兼容
    judgePostsCompatible(selectedPosts, postCode) {
      if (this.$store.state.user.userInfo.isAgentOperator !== '1') {
        return true
      }
      if (!selectedPosts || selectedPosts.length === 0) {
        return true
      }
      if (this.filterPosts.includes(postCode)) {
        return true
      }
      if ((this.judgePostsIncludes(selectedPosts, this.operateMangePosts) && this.operateMangePosts.includes(postCode))
        || (this.judgePostsIncludes(selectedPosts, this.traderPosts) && this.traderPosts.includes(postCode))
        || this.judgePostsIncludes(selectedPosts, this.sinkPosts) && this.sinkPosts.includes(postCode)
        || this.judgePostsIncludes(selectedPosts, this.ownChannelGridManager) && this.ownChannelGridManager.includes(postCode)

      ) {
        return true
      }
      return false
    },
    // 判断岗位包含关系
    judgePostsIncludes(selectedPosts, posts) {
      let filterCount = 0;
      for (let i = 0; i < selectedPosts.length; i++) {
        if (this.filterPosts.includes(selectedPosts[i])) {
          filterCount ++;
        }
        if (posts.includes(selectedPosts[i])) {
          return true
        }
      }
      if ((filterCount === selectedPosts.length) ) {
        return true
      }
      return false
    },
    // 获取数据库中的管辖区域 和管辖门店
    getNewInfo() {
      let data = {
        accountId:this.accountName,
        positionIdList: this.firstPostionArr,
        matklIdList:this.markrtIds
      }
      editRegionBinding( data ).then (res=> {
        if(res.data.code == 0 && res.data.data.gridList && res.data.data.gridList.length >0 ){
          this.JurisdictionalAreaList = res.data.data.gridList.map(obj => {
            return obj.gridName;
          });
        }
      })
    },
    getPostionArr(value) {
      this.bindCachePosts = value
    },
    // 岗位切换
    btnPostionClickLevel(index, id, item) {
      this.positinLevel = []
      this.isActivePLevel = id
      item.isCheck = !item.isCheck
      this.$forceUpdate()
      this.positinLevel.push(id)
      if(item.isCheck ) {
        // 操盘手不可以与其他运营经理岗位同时选择；下沉经理不可以与运营经理相关岗位同时选择
        if (!this.judgePostsCompatible(this.firstPostionArr, id)) {
          this.$message.warning('操盘手不可以与其他运营经理岗位同时选择;下沉经理不可以与运营经理相关岗位同时选择;非下沉渠道网格经理不可以与运营经理及下沉经理岗位同时选择;')
          item.isCheck = false
          return
        }
        this.firstPostionArr.push(id)
        // 筛除以后的数据
        if (!this.judgePostsCompatible(this.bindCachePosts, id)) {
          this.clear()
          this.bindCachePosts = []
        }


      } else {
        // 如果选中又取消 就删除对应的数据
        this.firstPostionArr.splice( this.firstPostionArr.indexOf(id),1)
      }
      // 如果选中的数据有业务员的选项就弹出业务员数据

      if (this.firstPostionArr.indexOf(14181287847) !== -1
        || this.firstPostionArr.indexOf(14181287852) !== -1
        || this.firstPostionArr.indexOf(14181287855) !== -1
        || this.firstPostionArr.indexOf(17594638392) !== -1
        || this.firstPostionArr.indexOf(20231110) !== -1

      ) {
        this.baseMatklList.forEach(item => {
          item.isCheck = false
        });
        this.businessFlag = true
      } else if(this.firstPostionArr.indexOf(14181287851) !== -1
        || this.firstPostionArr.indexOf(14181287853) !== -1){
        this.personId = '';//清空人员档案id
        this.businessFlag = false
        if(this.accountType == '管理账号') {
          this.materialGroupGroup.forEach(item=>{
            item.isCheck = true
          })
        }
      } else {
        this.personId = '';//清空人员档案id
        this.businessFlag = false
      }

      if( (
        // this.firstPostionArr.indexOf(14181287847) !== -1 ||
        this.firstPostionArr.indexOf(14181287852) !== -1
          || this.firstPostionArr.indexOf(14181287855) !== -1
          || (this.firstPostionArr.indexOf(17594638392) !== -1 && this.noBoundGrid == true)
        )
        && this.$store.state.user.userInfo.isAgentOperator == 1 ){
        this.isShowShop = true
      } else {
        this.isShowShop = false

      }

    },
    mainEditMsg(){
      if(this.accountType == '管理账号'){
        return true
      }else{
        return false
      }
    },
    btnTodoClickLevel(index, id, item) {
      if(this.mainEditMsg()){
        return
      }
      this.isActivetodoLevel = index
      this.todoLevel = id
      item.isCheck = !item.isCheck

      if (index == 0 && item.isCheck == true) {
        this.todoList.forEach(item => {
          item.isCheck = true
        })
      }
      if (index == 0 && item.isCheck == false) {
        this.todoList.forEach(item => {
          item.isCheck = false
        })
      }

      let materialNum = []
      this.todoList.forEach(item=>{
        if(item.isCheck == true && item.name!=='全部') {
          materialNum.push(item)
        }
      })
      if(materialNum.length == this.todoList.length-1 ){
        this.todoList[0].isCheck = true
      } else {
        this.todoList[0].isCheck = false
      }
    },
    btnnoticeClickLevel(index, id, item) {
      if(this.mainEditMsg()){
        return
      }
      this.isActiveNoticeLevel = index
      this.NoticeLevel = id
      item.isCheck = !item.isCheck

      if (index == 0 && item.isCheck == true) {
        this.nocticeList.forEach(item => {
          item.isCheck = true
        })
      }
      if (index == 0 && item.isCheck == false) {
        this.nocticeList.forEach(item => {
          item.isCheck = false
        })
      }
      let materialNum = []
      this.nocticeList.forEach(item=>{
        if(item.isCheck == true && item.name!=='全部') {
          materialNum.push(item)
        }
      })
      if(materialNum.length == this.nocticeList.length-1 ){
        this.nocticeList[0].isCheck = true
      } else {
        this.nocticeList[0].isCheck = false
      }
    },
    // 校验岗位之前是自有渠道网格经理现在更改了
    verificationPosition(){
      if (this.$store.state.user.userInfo.isAgentOperator == 1){
        let beforePostionArr =  this.firstPostionListShow.map(it=>{
          return it.id
        })
        // 如果之前是自有渠道网格经理 并且绑定了网格区域 就弹出提示 否则不弹出
        if ((beforePostionArr.indexOf(20231110)!== -1 && this.firstPostionArr.indexOf(20231110) == -1)&&this.JurisdictionalAreaList.length>0){
          return true
        }else{
          return false
        }
      } else {
        return false
      }
    },
    sumbitBefore(){
      var reg_tel = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;    //11位手机号码正则
      if(!this.businessFlag){
        if (!reg_tel.test(this.phone)) {
          this.showText = true
          this.$message.warning('请填写正确的手机号')
          return false
        }

        //  2021-6-16 修改邮箱校验规则
        var re = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/
        if (!re.test(this.eamil)) {
          this.showTextE = true
          this.$message.warning('请填写正确的邮箱')
          return false
        }
      }else{
        if(!this.personId ){
          this.$message.warning('请选择人员档案')
          return false
        }
      }
      this.materialGroupGroup.forEach(item => {
        if (item.isCheck == true && item.id !== 0) {
          this.materialGroup.push(item.id)
        }
      })
      let noticeCherk = []
      this.nocticeList.forEach(item => {
        if (item.isCheck == true && item.id !== 0) {
          noticeCherk.push(item.id)
        }
      })
      let tocoCherk = []
      this.todoList.forEach(item => {
        if (item.isCheck == true && item.id !== 0) {
          tocoCherk.push(item.id)
        }
      })
      let roleCherk = []
      this.roleList.forEach(item => {
        if (item.isCheck == true && item.id !== 0) {
          roleCherk.push(item.id)
        }
      })
      let markrtCherk = []
      this.materialGroupGroup.forEach(item => {
        if (item.isCheck == true && item.id !== 0) {
          markrtCherk.push(item.id)
        }
      })
      if (markrtCherk.length <= 0) {
        this.$message.warning('请选择物料组!')
        return
      }
      if (roleCherk.length <= 0 && this.accountType == '用户账号') {
        this.$message.warning('请选择角色!')
        return
      }
      if (this.firstPostionArr.length <= 0 && this.accountType == '用户账号') {
        this.$message.warning('请选择岗位!')
        return
      }
      if (noticeCherk.length <= 0 && this.accountType == '用户账号') {
        this.$message.warning('请选择通知!')
        return
      }
      if (tocoCherk.length <= 0 && this.accountType == '用户账号') {
        this.$message.warning('请选择待办!')
        return
      }
      if((this.verificationPosition() || this.isgridManager())&&(!this.ldapUserId||!this.markrtIds)){
        this.officePopups = true
      } else {
        this.sumbmit()
      }
    },
    // 选择的办事处经理和营销中心
    getOffice(obj){
      this.ldapUserId = obj.ldapUserId
      this.marketCenterIds = obj.marketCenterIds
      // this.sumbmit()
    },
    // 确定提交
    sumbmit() {
      var reg_tel = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;    //11位手机号码正则
      if(!this.businessFlag){
        if (!reg_tel.test(this.phone)) {
          this.showText = true
          this.$message.warning('请填写正确的手机号')
          return false
        }

        //  2021-6-16 修改邮箱校验规则
        var re = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/
        if (!re.test(this.eamil)) {
          this.showTextE = true
          this.$message.warning('请填写正确的邮箱')
          return false
        }
      }else{
        if(!this.personId ){
          this.$message.warning('请选择人员档案')
          return false
        }
      }
      this.materialGroupGroup.forEach(item => {
        if (item.isCheck == true && item.id !== 0) {
          this.materialGroup.push(item.id)
        }
      })
      let noticeCherk = []
      this.nocticeList.forEach(item => {
        if (item.isCheck == true && item.id !== 0) {
          noticeCherk.push(item.id)
        }
      })
      let tocoCherk = []
      this.todoList.forEach(item => {
        if (item.isCheck == true && item.id !== 0) {
          tocoCherk.push(item.id)
        }
      })
      let roleCherk = []
      this.roleList.forEach(item => {
        if (item.isCheck == true && item.id !== 0) {
          roleCherk.push(item.id)
        }
      })
      let markrtCherk = []
      this.materialGroupGroup.forEach(item => {
        if (item.isCheck == true && item.id !== 0) {
          markrtCherk.push(item.id)
        }
      })
      if (markrtCherk.length <= 0) {
        this.$message.warning('请选择物料组!')
        return
      }
      if (roleCherk.length <= 0 && this.accountType == '用户账号') {
        this.$message.warning('请选择角色!')
        return
      }
      if (this.firstPostionArr.length <= 0 && this.accountType == '用户账号') {
        this.$message.warning('请选择岗位!')
        return
      }
      if (noticeCherk.length <= 0 && this.accountType == '用户账号') {
        this.$message.warning('请选择通知!')
        return
      }
      if (tocoCherk.length <= 0 && this.accountType == '用户账号') {
        this.$message.warning('请选择待办!')
        return
      }

      let data = {
        'categorySeries': this.accountTypeUserShow?'':(this.accountHisenseType?'1':'0'), // 是否是海信系
        'userName': this.name,
        'phone': this.phone,
        'email': this.eamil,
        'businessFlag': this.businessFlag ? 1 : 0,
        "custId": this.$route.query.shopId,//主账号商家id
        "custAccountId": this.accountid,//选中账号id
        "operateType": "edit",//操作类型 add 新增，edit 编辑
        "matklIds": markrtCherk, //  //物料ids
        "roleIds": roleCherk,//角色ids
        "wareHouseIds": JSON.parse(JSON.stringify(this.ckName)), // 仓库id
        "shopIds": [],// 门店id
        // "workIds":this.isActivePLevel? this.isActivePLevel.toString().split(",").map(i => parseInt(i)) :[],
        // 如果是老板岗位的话 就写死传值
        "workIds":this.firstPostionArr,
        "noticeIds": noticeCherk,
        "dealIds": tocoCherk,
        "personId": this.personId,
        marketCenterIds:this.marketCenterIds,
        ldapUserId:this.ldapUserId,
      }

      this.spinning = true;
      savePermission(data).then(res => {
        if (res.data.code == 'success') {
          this.$message.success(res.data.msg)
          this.spinning = true;
          // 返回人员管理页面
          this.$router.push({
            path: '/basicInfo',
            query: {
              type: 1
            }
          })
        }
        else if (res.data.code == 400 ){
          this.spinning = false;
        } else  {
          this.$message.warning(res.data.msg)
          this.spinning = false;
        }
      }).catch(res=>{

        this.$message.warning(res.data.msg)
        this.spinning = false;
      })
    },
  }
}